<template>
  <div class="wrapper">
    <section class="content">
      <div class="container-fluid">
        <div class="row m-2">
          <!-- left column -->
          <div class="col-md-8">

          </div>
          <div class="col-md-4 text-right">
            <button v-show="showSync" class="btn btn-warning" @click="syncAccount">Sync Account</button>
            <span v-show="!showSync">Syncing in progress</span>
          </div>
        </div>
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- general form elements -->

            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">
                  {{ accountDetails.Acctnum }} Account Details
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <div class="row">
                  <!-- left column -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="acctCompany">Company</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctCompany"
                          placeholder="Company"
                          readonly
                          :value="accountDetails.Company"
                      />
                    </div>
                    <div class="form-group">
                      <label for="phone">Phone</label>
                      <input
                          type="text"
                          class="form-control"
                          id="phone"
                          placeholder="Phone"
                          readonly
                          :value="accountDetails.Phone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="fax">Fax</label>
                      <input
                          type="text"
                          class="form-control"
                          id="fax"
                          placeholder="Fax"
                          readonly
                          :value="accountDetails.Fax"
                      />
                    </div>
                    <div class="form-group">
                      <label for="contact">Contact</label>
                      <input
                          type="text"
                          class="form-control"
                          id="contact"
                          placeholder="Contact"
                          readonly
                          :value="accountDetails.Contact"
                      />
                    </div>
                    <div class="form-group">
                      <label for="title">Title</label>
                      <input
                          type="text"
                          class="form-control"
                          id="title"
                          placeholder="Title"
                          readonly
                          :value="accountDetails.Title"
                      />
                    </div>
                    <div class="form-group">
                      <label for="shipvia">Ship Via</label>
                      <input
                          type="text"
                          class="form-control"
                          id="shipvia"
                          placeholder="Ship via"
                          readonly
                          :value="accountDetails.Shipvia"
                      />
                    </div>
                    <div class="form-group">
                      <label for="Sls">Sls</label>
                      <input
                          type="text"
                          class="form-control"
                          id="Sls"
                          placeholder="Sls"
                          readonly
                          :value="accountDetails.Sls"
                      />
                    </div>
                    <div class="form-group">
                      <label for="terms">Terms</label>
                      <input
                          type="text"
                          class="form-control"
                          id="terms"
                          placeholder="Terms"
                          readonly
                          :value="accountDetails.Terms"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="acctAddress">Address</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctAddress"
                          placeholder="Address"
                          readonly
                          :value="accountDetails.Address1"
                      />
                    </div>
                    <div class="form-group">
                      <label for="acctAddress2">Address 2</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctAddress2"
                          placeholder="Address"
                          readonly
                          :value="accountDetails.Address2"
                      />
                    </div>
                    <div class="form-group">
                      <label for="acctCity">City</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctCity"
                          placeholder="City"
                          readonly
                          :value="accountDetails.City"
                      />
                    </div>
                    <div class="form-group">
                      <label for="acctState">State</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctState"
                          placeholder="State"
                          readonly
                          :value="accountDetails.State"
                      />
                    </div>
                    <div class="form-group">
                      <label for="acctZip">Zip</label>
                      <input
                          type="text"
                          class="form-control"
                          id="acctZip"
                          placeholder="Zip"
                          readonly
                          :value="accountDetails.Zip"
                      />
                    </div>
                    <div class="form-group">
                      <label for="routeno">Route #</label>
                      <input
                          type="text"
                          class="form-control"
                          id="routeno"
                          placeholder="Route No"
                          readonly
                          :value="accountDetails.RouteNo"
                      />
                    </div>
                    <div class="form-group">
                      <label for="fcharge">Finance Charge</label>
                      <input
                          type="text"
                          class="form-control"
                          id="fcharge"
                          placeholder="Finance Charge"
                          readonly
                          :value="accountDetails.ChargeFinance"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
        </div>
        <!-- /.row -->

        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- general form elements -->

            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">
                  Customizable data
                </h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <label for="wheretobuy">Show on Where to Buy</label>
                    <select id="wheretobuy" class="form-control" v-model="accountDetails.visible" @change="updateCustomData">
                      <option value="0">Don't Show</option>
                      <option value="1">Show</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showBilling">
          <div class="col-md-12">
            <div class="card collapsed-card">
              <div class="card-header">
                <h3 class="card-title">Billing Methods</h3>
                <div class="card-tools">
                  <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                      @click="
                      billBtnShown
                        ? (billBtnShown = false)
                        : (billBtnShown = true)
                    "
                  >
                    <i class="fas fa-plus fa-lg"></i>
                  </button>
                </div>
                <button
                    type="button"
                    class="btn btn-primary float-right"
                    data-toggle="modal"
                    v-show="billBtnShown"
                    data-target="#methodModal"
                >
                  Add Billing Method
                </button>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                  <tr>
                    <th>Nickname</th>
                    <th>Last Four</th>
                    <th>Exp</th>
                    <th>Type</th>
                    <th>Default</th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="bill in accountDetails.billing" :key="bill.id">
                    <td>{{ bill.name }}</td>
                    <td>{{ bill.last_four }}</td>
                    <td>{{ bill.expmonth + " - " + bill.expyear }}</td>
                    <td>{{ bill.type }}</td>
                    <td>
                      <input
                          type="radio"
                          name="billDefault"
                          :checked="bill.default"
                          @click="updateDefault(bill.id)"
                      />
                    </td>
                    <td>
                      <button
                          type="submit"
                          class="btn btn-warning float-right ml-3"
                          @click="editMethod(bill.id)"
                      >
                        Edit
                      </button>
                      <button
                          type="submit"
                          class="btn btn-danger float-right ml-3"
                          @click="deleteMethod(bill.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card collapsed-card">
              <div class="card-header">
                <h3 class="card-title">Orders</h3>
                <div class="card-tools">
                  <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                      @click="loadOrders"
                  >
                    <i class="fas fa-plus fa-lg"></i>
                  </button>
                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body p-1">
                <Orders
                    :acctnum="parseInt(accountid)"
                    :filter="false"
                    ref="ordersList"
                ></Orders>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <div
        class="modal fade"
        id="methodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="methodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="methodModalLabel">Billing Method</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <strong>Credit Card</strong>
                      <small class="ml-2">enter your card details</small>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="name">Card Nickname</label>
                            <input
                                class="form-control"
                                id="nickname"
                                type="text"
                                placeholder="Nickname"
                                v-model="form.name"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="name">Billing Information</label>
                            <input
                                class="form-control"
                                id="billing_name"
                                type="text"
                                placeholder="Customer Name"
                                v-model="form.billing_name"
                            />
                            <span class="bg-danger" v-if="v$.form.billing_name.$error">Field required</span>
                            <input
                                class="form-control mt-2"
                                id="billing_address"
                                type="text"
                                placeholder="Address"
                                v-model="form.billing_address"
                            />
                            <span class="bg-danger" v-if="v$.form.billing_address.$error">Field required</span>
                            <input
                                class="form-control mt-2"
                                id="billing_city"
                                type="text"
                                placeholder="City"
                                v-model="form.billing_city"
                            />
                            <span class="bg-danger" v-if="v$.form.billing_city.$error">Field required</span>
                            <input
                                class="form-control mt-2"
                                id="billing_state"
                                type="text"
                                placeholder="State"
                                v-model="form.billing_state"
                            />
                            <span class="bg-danger" v-if="v$.form.billing_state.$error">Field required</span>
                            <input
                                class="form-control mt-2"
                                id="billing_postal"
                                type="text"
                                placeholder="Postal Code"
                                v-model="form.billing_postal"
                            />
                            <span class="bg-danger" v-if="v$.form.billing_postal.$error">Field required</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="ccnumber">Credit Card Number</label>
                            <div>
                              <div class="tokenBox" id="tokenExIframeDiv"></div>
                            </div>
                            <label for="ccnumber">CVV</label>
                            <div class="cvvbox" id="cvvbox">
                              <input type="text" class="form-control" v-model="form.cvv">
                            </div>
                            <span class="bg-danger" v-if="v$.form.cvv.$error">Field required</span>
                            <!--<span class="bg-danger" v-if="v$.form.token.$error">Field required</span> -->
                            <span class="bg-danger" v-if="cardError">{{ cardErrorMsg }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-sm-4">
                          <label for="ccmonth">Month</label>
                          <select
                              class="form-control"
                              id="expmonth"
                              v-model="form.expmonth"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <span class="bg-danger" v-if="v$.form.expmonth.$error">Field required</span>
                        </div>
                        <div class="form-group col-sm-4">
                          <label for="ccyear">Year</label>
                          <select
                              class="form-control"
                              id="expyear"
                              v-model="form.expyear"
                          >
                            <option
                                v-for="yr in yearRange"
                                :value="yr"
                                :key="yr"
                            >
                              {{ yr }}
                            </option>
                          </select>
                          <span class="bg-danger" v-if="v$.form.expyear.$error">Field required</span>
                        </div>
                        <!--
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="cvv">CVV/CVC</label>
                            <input class="form-control" id="cvv" type="text" placeholder="123">
                          </div>
                        </div>
                        -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="clearAddBilling()"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" id="btnSubmit1">
              Add
            </button>
          </div>


        </div>
      </div>
    </div>


    <div
        class="modal fade"
        id="editMethodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editMethodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editMethodModalLabel">Edit Method</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="name">Billing Information</label>
                    <input
                        class="form-control"
                        id="billing_name"
                        type="text"
                        placeholder="Customer Name"
                        v-model="editform.billing_name"
                    />
                    <span class="bg-danger" v-if="v$.editform.billing_name.$error">Field required</span>
                    <input
                        class="form-control mt-2"
                        id="billing_address"
                        type="text"
                        placeholder="Address"
                        v-model="editform.billing_address"
                    />
                    <span class="bg-danger" v-if="v$.editform.billing_address.$error">Field required</span>
                    <input
                        class="form-control mt-2"
                        id="billing_city"
                        type="text"
                        placeholder="City"
                        v-model="editform.billing_city"
                    />
                    <span class="bg-danger" v-if="v$.editform.billing_city.$error">Field required</span>
                    <input
                        class="form-control mt-2"
                        id="billing_state"
                        type="text"
                        placeholder="State"
                        v-model="editform.billing_state"
                    />
                    <span class="bg-danger" v-if="v$.editform.billing_state.$error">Field required</span>
                    <input
                        class="form-control mt-2"
                        id="billing_postal"
                        type="text"
                        placeholder="Postal Code"
                        v-model="editform.billing_postal"
                    />
                    <span class="bg-danger" v-if="v$.editform.billing_postal.$error">Field required</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="ccmonth">Month</label>
                  <select
                      class="form-control"
                      id="expmonth"
                      v-model="editform.expmonth"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>

                </div>
                <div class="form-group col-sm-4">
                  <label for="ccyear">Year</label>
                  <select
                      class="form-control"
                      id="expyear"
                      v-model="editform.expyear"
                  >
                    <option
                        v-for="yr in yearRange"
                        :value="yr"
                        :key="yr"
                    >
                      {{ yr }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" id="btnEditSave" @click.prevent="updateCard">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from "jquery";
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import Orders from "@/components/Admin/Orders.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "AccountDetails",
  props: ["accountid"],
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast, v$: useVuelidate()};
  },
  data() {
    return {
      cardErrorMsg: '',
      cardError: false,
      showBilling: false,
      showSync: false,
      editform: {
        id: "",
        expyear: "",
        expmonth: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_postal: "",
        billing_name: "",
      },
      form: {
        name: "",
        Acctnum: "",
        token: "",
        expyear: "",
        expmonth: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_postal: "",
        billing_name: "",
        rawdata: "",
        last_four: "",
        type: "",
        cvv:"",
      },
      loadOrderBtn: false,
      billBtnShown: false,
      iframe: "",
      isBtnLoaded: false,
      yearRange: [],
      search: "",
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ["csv"]},
    };
  },
  components: {
    Orders: Orders,
  },
  computed: {
    ...mapGetters({
      accountDetails: "Accounts/getAccountDetails",
      UserInfo: "userAccess/user"
    }),
    rules() {
      return {
        billing_address: {required},
        billing_city: {required},
        billing_state: {required},
        billing_postal: {required},
        billing_name: {required},
        token: {required},
        type: {required},
        expyear: {required},
        expmonth: {required},
        cvv: {required}
      };
    },
    editrules() {
      return {
        billing_address: {required},
        billing_city: {required},
        billing_state: {required},
        billing_postal: {required},
        billing_name: {required},
        expyear: {required},
        expmonth: {required},
      };
    },
  },
  validations() {
    return {
      form: this.rules,
      editform: this.editrules,
    };
  },
  methods: {
    updateCustomData(){
      this.$store
          .dispatch("Accounts/updateAcctCustData", {id: this.accountid, visible:this.accountDetails.visible})
          .then(() => {
            //do a save popup
          });
    },
    loadOrders() {
      if (!this.loadOrderBtn) {
        this.loadOrderBtn = true;
        this.$refs.ordersList.loadData();
      }
    },
    updateDefault(id) {
      this.$store
          .dispatch("Accounts/updateMethodDefault", {id, default: true})
          .then(() => {
            //do a save popup
          });
    },
    syncAccount() {
      this.$store
          .dispatch("Accounts/syncAccount", {account: this.accountid})
          .then(() => {
            //do a save popup
            this.showSync = false;
            this.toast.success("Account set to sync");
          });
    },
    clearForm() {
      const keys = Object.keys(this.form);
      // eslint-disable-next-line no-unused-vars
      keys.forEach((key, index) => {
        this.form[key] = "";
      });
    },
    expYearRange() {
      let lowEnd = new Date().getFullYear();
      let highEnd = new Date().getFullYear() + 10;
      while (lowEnd <= highEnd) {
        this.yearRange.push(lowEnd++);
      }
    },
    async updateCard() {

      if (!await this.v$.editform.$validate()) {
        return
      }

      this.$store.dispatch("Accounts/updateMethod", this.editform).then(data => {
        let bs = data.billing
        if (bs.success && bs.paymentSuccess || bs.id !== undefined) {
          //success
          this.$store.dispatch("Accounts/accountDetails", {
            Acctnum: this.accountid,
          });

          this.toast.success("Billing Method Updated!");
          this.editform.id = ''
          this.editform.expmonth = ''
          this.editform.expyear = ''
          this.editform.billing_address = ''
          this.editform.billing_city = ''
          this.editform.billing_name = ''
          this.editform.billing_postal = ''
          this.editform.billing_state = ''
          $("#editMethodModal").modal("hide");

        } else if (!bs.success || !bs.paymentSuccess) {
          if (bs.msg !== undefined) {
            this.toast.error(bs.msg);
          } else if (bs.message !== undefined) {
            this.toast.error(bs.message);
          }
        }

      });
    },
    editMethod(billingid) {

      this.editform.id = ''
      this.editform.expmonth = ''
      this.editform.expyear = ''
      const foundObject = this.accountDetails.billing.find((obj) => obj.id === billingid);
      if (foundObject) {
        console.log('fo',foundObject)
        this.editform.id = billingid
        this.editform.expmonth = foundObject.expmonth
        this.editform.expyear = foundObject.expyear
        this.editform.billing_address = foundObject.billing_address
        this.editform.billing_city = foundObject.billing_city
        this.editform.billing_name = foundObject.billing_name
        this.editform.billing_postal = foundObject.billing_postal
        this.editform.billing_state = foundObject.billing_state
      }
      $("#editMethodModal").modal("show");
    },
    deleteMethod(billingid) {
      this.$store.dispatch("Accounts/deleteMethod", {billingid}).then(() => {
        this.toast.error("Billing Method Deleted!");
      });
    },
    async validateFields() {
      return await this.v$.form.$validate()
    },
    async addMethod() {
      if (!await this.validateFields()) {
        return
      }
      this.$store.dispatch("Accounts/addMethod", this.form).then(rez => {
        //$('#tx_iframe_tokenExIframeDiv').remove();
        console.log('rez', rez);
        const billingFailure = rez.billing.paymentSuccess !== undefined && !rez.billing.paymentSuccess;
        const legacyFailure = rez.success !== undefined && !rez.success;

        if (billingFailure) {
          this.toast.error("There was an issue with the card " + (rez.billing.message || ""));
        } else if (legacyFailure) {
          this.toast.error("There was an issue with the card");
        } else {
          this.iframe.reset();
          $("#methodModal").modal("hide");
        }
      });
    },
    clearAddBilling() {
      this.iframe.reset();
    }
  },
  mounted() {
    const hasAdmin = this.UserInfo.roles.find(obj => obj.id === 1);
    if (hasAdmin) {
      this.showBilling = true
    }

    this.expYearRange();
    let self = this;
    this.$store.dispatch("Accounts/accountDetails", {
      Acctnum: this.accountid,
    }).then(res => {
      self.showSync = !res.fullsync
    });

    $("#editMethodModal").on("show.bs.modal", async function () {
      console.log('open modal')
    });

    $("#methodModal").on("show.bs.modal", async function () {
      await self.clearForm();
      self.form.Acctnum = self.accountid;

      self.form.billing_name = self.accountDetails?.Company
      self.form.billing_address = self.accountDetails?.Address1 + ' ' + self.accountDetails?.Address2
      self.form.billing_city = self.accountDetails?.City
      self.form.billing_state = self.accountDetails?.State
      self.form.billing_postal = self.accountDetails?.Zip

      if (!self.isBtnLoaded) {
        let iframeConfig = await self.$store.dispatch(
            "Accounts/getTokenXCreds"
        );
        // eslint-disable-next-line no-undef
        self.iframe = new TokenEx.Iframe("tokenExIframeDiv", iframeConfig);

        self.iframe.on("load", function () {
          console.log("iFrame Loaded");
        });
        self.iframe.on("focus", function () {
          console.log("iFrame Focus");
        });
        self.iframe.on("blur", function () {
          console.log("iFrame blur");
          self.iframe.validate();
        });
        self.iframe.on("validate", function (data) {
          console.log("iFrame validate:" + JSON.stringify(data));
          if (data.isValid) {
            /*
            self.cardError = false;
            if (!data.isCvvValid) {
              self.cardError = true;
              if (data.cvvValidator === 'format') {
                self.cardErrorMsg = 'CVV not valid';
              } else {
                self.cardErrorMsg = 'CVV required';
              }
            } else {
            */
            self.validateFields()
            //}
            /*
          } else {
            self.cardError = true;

            if (data.validator === 'format') {
              self.cardErrorMsg = 'Card not valid';
            } else {
              self.cardErrorMsg = 'Card required';
            }
          }
          */
          }
        });
        self.iframe.on("cardTypeChange", function (data) {
          console.log("iFrame cardTypeChange:" + JSON.stringify(data));
        });
        self.iframe.on("tokenize", function (data) {
          console.log("iFrame Tokenize:", data);
          console.log("iFrame form:", self.form);
          self.form.rawdata = JSON.stringify(data);
          self.form.token = data.token;
          self.form.last_four = data.lastFour;
          self.form.type = data.cardType;

          self.addMethod();
        });
        self.iframe.on("error", function (data) {
          console.log("iFrame error:" + JSON.stringify(data));
        });
        console.log('loaded')

        $("#btnSubmit1")
            .off()
            .on("click", function (event) {
              console.log('clicked')
              event.preventDefault();
              self.iframe.tokenize();
            });
        self.isBtnLoaded = true;

        self.iframe.load();
      }
    });
  },
};
</script>

<style scoped>
.tokenBox {
  height: 70px;
}
.cvvbox {
  width:100px;
}
</style>
